import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
export default function Personal() {
  const data = useStaticQuery(graphql`
    {
      allWpPerson(sort: { fields: title, order: ASC }) {
        nodes {
          title
          personalinfo {
            epost
            telefon
            titel
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 0.8, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `)
  const personer = data.allWpPerson.nodes
  return (
    <div className="grid xl:grid-cols-2 gap-4">
      {personer.map((person, i) => {
        return <Person key={i} person={person} />
      })}
    </div>
  )
}

function Person({ person }) {
  const { title, personalinfo, featuredImage } = person
  const { epost, telefon, titel } = personalinfo
  const img = featuredImage?.node.localFile.childImageSharp.gatsbyImageData
  return (
    <div className="bg-primary/10 text-base md:text-sm">
      <div className="flex flex-row gap-2 items-center">
        <div className="w-48 xl:w-36 flex-none h-full">
          {featuredImage ? (
            <GatsbyImage image={getImage(img)} />
          ) : (
            <div className="opacity-50">
              <StaticImage src="../../images/personal/defaultimage.jpg" />
            </div>
          )}
        </div>
        <div className="w-2/3 flex-auto ">
          <div className="p-2">
            <div className="flex flex-col  flex-wrap leading-normal">
              <h5>{title}</h5>
              <div>{titel}</div>
            </div>
            <div className="">
              <p>{telefon && telefon}</p>
              <p>
                {" "}
                <a href={"mailto:" + epost} className="underline">
                  Epost
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
