import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "./Link"

export default function SidebarMenu({ headline }) {
  const data = useStaticQuery(graphql`
    {
      allWpMenuItem(
        filter: { parentDatabaseId: { eq: 88 } }
        sort: { order: ASC, fields: order }
      ) {
        nodes {
          id
          label
          url
        }
      }
    }
  `)
  const menuItems = data.allWpMenuItem.nodes
  console.log(menuItems)
  return (
    <div>
      <h4>{headline}</h4>
      <ul className="">
        {menuItems.map((item, i) => {
          return (
            <li key={i}>
              <Link
                to={item.url}
                activeClassName="text-secondary/80 font-medium"
              >
                {item.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
