import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import SingleStandardPage from "../components/common/SingleStandardPage"
import SingleKontakt from "../components/common/SingleKontakt"
import SingleGeneralPage from "../components/common/SingleGeneralPage"

export default function PageTemplate({ data }) {
  const page = data.wpPage
  return (
    <div className="">
      <Seo title={data.wpPage.title} />
      <Layout>
        {page.parentDatabaseId === 37 ? (
          <SingleStandardPage page={page} />
        ) : page.databaseId === 49 ? (
          <SingleKontakt page={page} />
        ) : (
          <SingleGeneralPage page={page} />
        )}
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      databaseId
      parentDatabaseId
      kontaktinfo {
        adress
        fakturaadress
        telefonOchMail
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
