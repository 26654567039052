import { Link } from "gatsby"
import React from "react"
import FeaturedImagePage from "../common/FeaturedImagePage"
import Personal from "../kontakt/Personal"
import H1 from "../layout/H1"

export default function SingleStandardPage({ page }) {
  const { title, content, featuredImage, kontaktinfo } = page
  const image = featuredImage?.node.localFile.childImageSharp
  const { adress, fakturaadress, telefonOchMail } = kontaktinfo
  const html = `<div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Trindyxegatan%202&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br><style>.mapouter{position:relative;text-align:right;height:500px;width:100%;}</style><style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}</style></div></div>`
  return (
    <div className="min-h-screen  flex flex-col items-center justify-start">
      <div className=" w-full relative bg-gradient-to-t bg-black">
        {image && <FeaturedImagePage image={image} />}
        <div className="absolute top-0 w-full h-full flex flex-col justify-center ">
          <div className="px-4 md:px-24 max-w-screen-2xl mx-auto w-full">
            <H1 text={title} />
          </div>
        </div>
      </div>
      <div className=" relative px-4 md:px-24 max-w-screen-2xl w-full space-y-4 md:space-y-8 py-8 md:py-16">
        <div className="page-content flex flex-col md:flex-row gap-8 md:gap-16 border-secondary border-t-4">
          <div className="w-full md:w-2/3 md:pt-8 flex-none space-y-8">
            <h4>Personal</h4>
            <Personal />

            <h4>Karta</h4>
            <div className="w-full bg-primary/30">
              <div className="" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
          <div className="w-full md:w-1/3 order-first md:order-last">
            <div className="bg-secondary/20 p-4 md:p-8">
              <div className="space-y-4 md:space-y-8">
                <h4>Kontaktinfo</h4>
                <div>
                  <h5>Telefon och mail</h5>
                  <div
                    className="wp-content"
                    dangerouslySetInnerHTML={{ __html: telefonOchMail }}
                  />
                </div>
                <div>
                  <h5>Adress</h5>
                  <div
                    className="wp-content"
                    dangerouslySetInnerHTML={{ __html: adress }}
                  />
                </div>

                <div>
                  <h5>Fakturaadress</h5>
                  <div
                    className="wp-content"
                    dangerouslySetInnerHTML={{ __html: fakturaadress }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
